import React from 'react';

export const TermsOfUse = () => {
    return (
        <div style={{ direction: 'rtl', textAlign: 'initial', fontFamily: 'Roboto,arial,sans-serif', fontWeight: '400', fontSize: '14px' }}>
            <h3> ברוך הבא ל-Shop N' Dine! </h3>
            <br />
            תודה על השימוש במוצרים ובשירותים שלנו (להלן “השירותים”). השירותים ניתנים על ידי הנהלת האתר (להלן “Shop N' Dine”).
            <br /><br />
            השימוש שלך בשירותים מהווה הסכמה מצדך לתנאי שימוש אלה. נא קרא אותם בעיון.
            <br /><br />
            במקרים מסוימים ייתכן שיחולו תנאים נוספים או דרישות מוצר נוספות (לרבות מגבלת גיל). תנאים והגבלות נוספים יופיעו יחד עם השירותים הרלוונטיים ויהפכו לחלק מההסכם שלך איתנו, אם תשתמש במוצרים הללו.

            <h3> השימוש בשירותים שלנו </h3>
            <br />
            אתה נדרש לפעול בכפוף למדיניות המתפרסמת במסגרת השירותים.
            <br /><br />
            אל תשתמש בשירותים לרעה. לדוגמה, אל תשבש את השירותים שלנו ואל תנסה לגשת אליהם בכל דרך אחרת מלבד דרך הממשק וההוראות שאנו מספקים. אתה רשאי להשתמש בשירותים אך ורק כפי שמותר על פי דין, לרבות חוקים ותקנות החלים בנושא בקרה על ייצוא ועל ייצוא חוזר. לדוגמה, אנחנו עשויים להשעות או להפסיק לספק לך את השירותים אם אינך מציית לתנאים או למדיניות שלנו, או אם אנחנו חוקרים חשד להתנהגות בלתי הולמת.
            <br /><br />
            השימוש בשירותים שלנו אינו מקנה לך בעלות על זכויות קניין רוחני בשירותים או על התוכן שאתה מקבל גישה אליו. אינך רשאי להשתמש בתוכן מהשירותים שלנו אלא אם כן קיבלת אישור מבעל התוכן כפי שהחוק מתיר. תנאי שימוש אלה אינם מעניקים לך את הזכות להשתמש במיתוג או בסמלים כלשהם שנמצאים בשימוש בשירותים שלנו. אל תסיר, תסתיר או תשנה הודעות משפטיות המוצגות בתוך השירותים שלנו או בעת השימוש בהם.
            <br /><br />
            השירותים שלנו מציגים בין השאר גם תוכן שאינו של Shop N' Dine. תוכן זה נמצא באחריותו הבלעדית של הגוף שפרסם אותו. אנו עשויים לבחון תוכן כדי לקבוע אם הוא לא חוקי או אם הוא מפר את המדיניות שלנו, ואנו עשויים להסיר או לסרב להציג תוכן שיש לנו סיבה סבירה להאמין כי הוא מפר את המדיניות שלנו או את החוק. אך אין פירוש הדבר בהכרח כי אנו בוחנים את התוכן, על כן אל תניח כי אנו עושים זאת.
            <br /><br />
            אנו עשויים לשלוח לך הודעות שירות, הודעות מנהלתיות ומידע אחר בקשר לשימוש שלך בשירותים. אתה רשאי לבקש להפסיק את שליחתן של חלק מההודעות הללו.
            <br /><br />
            חלק מהשירותים שלנו זמינים במכשירים ניידים. אל תשתמש בשירותים כאלה בדרך שמסיחה את דעתך ומונעת ממך לציית לחוקי התנועה או לכללי הבטיחות בזמן נסיעה.

            <h3> חשבון Shop N' Dine שלך </h3>
            <br />
            ייתכן שתזדקק לחשבון Shop N' Dine כדי להשתמש בכמה מהשירותים שלנו. אתה רשאי ליצור חשבון Shop N' Dine משלך או שמנהל מערכת, כגון מעסיקך או מוסד לימודי, יקצה לך חשבון Shop N' Dine. אם אתה משתמש בחשבון Shop N' Dine שהקצה לך מנהל מערכת, ייתכן כי יחולו תנאים שונים או נוספים וייתכן כי למנהל המערכת תהיה יכולת לגשת לחשבונך או להשביתו.
            <br /><br />
            {/* TODO: add link to change passwod page */}
            כדי להגן על חשבון ה- Shop N' Dine שלך, שמור על סודיות הסיסמה שלך. אתה האחראי לפעילות המתרחשת בחשבון Shop N' Dine שלך או באמצעותו. נסה לא להשתמש שוב בסיסמת חשבון Shop N' Dine שלך באפליקציות של צד שלישי. אם נודע לך על שימוש לא מורשה בסיסמה או בחשבון Shop N' Dine שלך, <a href='#'>פעל בהתאם להוראות הבאות</a>.

            <h3> פרטיות והגנה על זכויות יוצרים </h3>
            <br />
            <a href="/info/privacy">מדיניות הפרטיות</a> של Shop N' Dine מסבירה כיצד אנו מטפלים במידע האישי שלך ומגנים על פרטיותך בזמן שאתה משתמש בשירותים שלנו. השימוש שלך בשירותים שלנו מהווה הסכמה מצדך לשימוש של Shop N' Dine במידע זה בהתאם למדיניות הפרטיות שלנו.
            <br /><br />
            אנחנו מגיבים להודעות על הפרות לכאורה של זכויות יוצרים ויכולים לבטל חשבונות של מפרים סדרתיים.
            <br /><br />
            אנו מספקים מידע כדי לסייע לבעלי זכויות יוצרים לנהל את הקניין הרוחני שלהם ברשת. אם אתה סבור כי מישהו מפר את זכויות היוצרים שלך וברצונך להודיע לנו, תוכל למצוא מידע על שליחת הודעות ועל המדיניות של Shop N' Dine בנוגע לתגובה להודעות <a href="/contactUs">במרכז העזרה שלנו</a>.

            <h3> התוכן שלך בשירותים שלנו </h3>
            <br />
            חלק מהשירותים שלנו מאפשרים לך להעלות, למסור, לאחסן, לשלוח או לקבל תוכן. הבעלות על זכויות הקניין הרוחני הקיימות לך בתוכן זה נותרת בידיך. במילים אחרות, מה ששייך לך נשאר שלך.
            <br /><br />
            כאשר אתה טוען, מוסר, מאחסן, שולח או מקבל תוכן בשירותים שלנו או דרכם, אתה נותן ל-Shop N' Dine (ולמי שעובד אתנו) רישיון עולמי להשתמש, לארח, לאחסן, לשכפל, לשנות, ליצור עבודות נגזרות (שמקורן למשל בתרגומים, בעיבודים או בשינויים אחרים שאנחנו עושים, כדי שהתוכן שלך יתאים יותר לשירותים שלנו), להודיע, לפרסם, לבצע בפומבי, להציג בפומבי ולהפיץ את התוכן שלך. הזכויות שאתה מעניק ברישיון זה נועדו רק למטרות הפעלה, קידום ושיפור השירותים שלנו ופיתוח שירותים חדשים. רישיון זה ימשיך להיות תקף גם אם תפסיק להשתמש בשירותים שלנו (לדוגמה, לגבי רישום עסק שהוספת). שירותים מסוימים עשויים להציע לך דרכים לגשת לתוכן שנוסף לאותם שירותים ולהסירו. בנוסף, בחלק מהשירותים, קיימים תנאים או הגדרות אשר מצמצמים את השימוש שלנו בתוכן שמשתמשים שולחים במסגרת השירותים הללו. ודא כי יש בידך הזכויות הנחוצות כדי להעניק לנו רישיון מסוג זה עבור כל תוכן שתשלח לשירותים שלנו.
            <br /><br />
            המערכות האוטומטיות שלנו מנתחות את התוכן שלך (כולל הודעות דוא"ל) כדי לספק לך תכונות מוצר רלוונטיות עבורך, כגון תוצאות חיפוש מותאמות אישית, פרסום מותאם אישית וזיהוי דואר זבל ותוכנות זדוניות. ניתוח זה מתרחש כאשר התוכן נשלח, כשהוא מתקבל וכשמאחסנים אותו.
            {/* <br /><br /> */}
            {/* אם יש לך חשבון Shop N' Dine, ייתכן שנציג את שם הפרופיל שלך, את תמונת הפרופיל ופעולות שאתה מבצע ב-Shop N' Dine או באפליקציות של צד שלישי הקשורות לחשבון Shop N' Dine שלך (כגון לחיצות על ‎+1, ביקורות שתכתוב ותגובות שתפרסם) בשירותים שלנו, כולל הצגה במודעות ובהקשרים מסחריים אחרים. אנחנו נכבד את הבחירות שלך להגביל את הגדרות השיתוף או החשיפה בחשבון Shop N' Dine שלך. לדוגמה, תוכל לבחור את ההגדרות כך ששמך והתמונה שלך לא יופיע במודעה. */}
            <br /><br />
            תוכל למצוא מידע נוסף על האופן שבו Shop N' Dine משתמשת בתוכן ומאחסנת אותו ב<a href="/info/privacy">מדיניות הפרטיות</a> או בתנאים נוספים של שירותים ספציפיים. אם אתה שולח משוב או הצעות לגבי השירותים, אנו עשויים להשתמש במשוב או בהצעות ללא כל מחויבות כלפיך.

            <h3> ההתחייבויות וכתבי הוויתור שלנו </h3>
            <br />
            אנו מספקים את השירותים ברמה מסחרית סבירה של מיומנות ואנו מקווים כי תיהנה להשתמש בהם. אך יש דברים מסוימים שאיננו מבטיחים לספק בקשר עם השירותים שלנו.
            <br /><br />
            פרט למצוין במפורש בתנאי שימוש אלה או בתנאים והגבלות נוספים, Shop N' Dine או הספקים או המפיצים שלה אינם מספקים הבטחות ספציפיות כלשהן בנוגע לשירותים. לדוגמה, איננו מספקים התחייבות כלשהי לגבי התוכן שבשירותים, לגבי הפונקציות הספציפיות של השירותים, או לגבי המהימנות, הזמינות או היכולת שלהם לענות על הצרכים שלך. אנו מספקים את השירותים “כמות שהם”.
            <br /><br />
            בתחומי שיפוט מסוימים מחייב הדין קיומן של התחייבויות מסוימות, כגון התחייבות משתמעת בנוגע לסחירות, התאמה למטרה מסוימת ואי-הפרה. ככל שמתיר החוק, איננו מציעים כל התחייבות.

            <h3> אחריות בגין השירותים שלנו </h3>
            <br />
            ככל שמתיר החוק, Shop N' Dine והספקים והמפיצים של Shop N' Dine לא יהיו אחראים לאובדן רווחים, הכנסות או נתונים, הפסדים כלכליים או נזקים עקיפים, מיוחדים, תוצאתיים, פיצויים לדוגמה או פיצויים עונשיים.
            <br /><br />
            ככל שמתיר החוק, האחריות הכוללת של Shop N' Dine ושל הספקים והמפיצים שלה בגין כל תביעה במסגרת תנאי שימוש אלה, לרבות בגין התחייבות משתמעת, תוגבל לסכום ששילמת לנו בגין השימוש בשירותים (או, לפי בחירתנו, בגין חידוש אספקת השירותים).
            <br /><br />
            בכל המקרים, Shop N' Dine והספקים והמפיצים שלה לא יהיו אחראים לאובדן או נזק כלשהו שאינו צפוי במידה סבירה.
            <br /><br />
            אנו מכירים בכך שבארצות מסוימות, ייתכן כי יהיו לך זכויות משפטיות בתור צרכן. אם אתה משתמש בשירותים למטרות אישיות, שום דבר מהאמור בתנאי שימוש אלה או בתנאים ובהגבלות נוספים אינו מגביל זכויות משפטיות צרכניות שלא ניתן לוותר עליהן באמצעות חוזה.

            <h3> שימושים עסקיים בשירותים </h3>

            אם אתה משתמש בשירותים שלנו מטעם עסק כלשהו, עסק זה מקבל את התנאים הבאים. העסק ישפה ויפצה את Shop N' Dine ואת הגופים המסונפים אליה, נושאי המשרה, הסוכנים והעובדים שלה מפני כל תביעה, טענה או פעולה הנובעת או הקשורה לשימוש שלך בשירותים או להפרה של תנאים אלה, לרבות כל חבות או הוצאה הנובעים מטענות, הפסדים, נזקים, תביעות, פסקי דין, הוצאות משפטיות ושכר טרחת עורכי דין מכל מין וסוג שהוא.

            <h3> מידע על תנאי שימוש אלה </h3>
            <br />
            אנו עשויים לשנות תנאי שימוש אלה או תנאים והגבלות נוספים שחלים על שירות, על מנת, לדוגמה, לשקף שינויים בחוק או שינויים בשירותים שלנו. עלייך לעיין בתנאי שימוש אלה באופן קבוע. אנו נפרסם הודעה על שינויים בתנאים אלה בדף זה. אנו נפרסם הודעה על שינויים בתנאים נוספים בשירות הרלוונטי. שינויים אלה לא יחולו רטרואקטיבית וייכנסו לתוקף ארבעה-עשר יום לפחות לאחר פרסומם. עם זאת, שינויים הקשורים באופן ספציפי לפונקציות חדשות בשירות כלשהו או לשינויים שבוצעו מסיבות משפטיות ייכנסו לתוקף באופן מיידי. אם אינך מסכים לשינויים בתנאים ובהגבלות בשירות מסוים, כדאי שתפסיק להשתמש בשירות זה.
            <br /><br />
            אם קיימת סתירה בין תנאי שימוש אלה לבין התנאים וההגבלות הנוספים, התנאים וההגבלות הנוספים הם שיקבעו בנוגע לאותה סתירה.
            <br /><br />
            תנאי שימוש אלה שולטים במערכת היחסים בין Shop N' Dine לבינך. הם אינם יוצרים זכויות לטובת צד שלישי.
            <br /><br />
            אם אינך מציית לתנאי שימוש אלה ואיננו נוקטים פעולה באופן מיידי, אין זה אומר שוויתרנו על זכויות כלשהן שעשויות להיות לנו (כגון נקיטת פעולה בעתיד).
            <br /><br />
            אם מתברר שתנאי מסוים אינו ניתן לאכיפה, אזי לא תהיה לכך כל השפעה על תנאים אחרים.
            <br /><br />
            בבתי המשפט בארצות מסוימות, חוקי מדינת ישראל אינם חלים במחלוקות מסוגים מסוימים. אם אתה מתגורר באחת מהארצות הללו, ובמקרים שבהם חוקי מדינת ישראל אינם חלים, יחולו חוקי ארץ מגוריך במחלוקות הקשורות לתנאי שימוש אלה. בכל מקרה אחר, אתה מסכים לכך שחוקי ישראל, יחולו בכל מקרה של מחלוקת הנובעת מתנאי שימוש אלה ומהשירותים או בקשר אליהם. בדומה לכך, אם בתי המשפט בארץ מגוריך אינם מתירים לך להסכים לסמכות השיפוט או למקום הדיון של בתי המשפט בישראל, יחולו סמכות השיפוט או מקום הדיון המקומיים בארצך במקרים של מחלוקות הקשורות לתנאי שימוש אלה. בכל מקרה אחר, כל התביעות הנובעות מתנאי שימוש אלה או השירותים או קשורות להם יעלו לדיון משפטי רק בבתי המשפט בישראל, ואתה ו-Shop N' Dine מסכימים לסמכות שיפוט פרסונלית של בתי משפט אלו.
            <br /><br />
            לקבלת מידע על יצירת קשר עם Shop N' Dine, נא בקר <a href="/info/contactUs">בדף יצירת הקשר שלנו‎</a>.
        </div>
    );
};