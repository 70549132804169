import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

// import DelayLink from 'react-delay-link';

import LayersIcon from '@material-ui/icons/Layers';
import StoreIcon from '@material-ui/icons/Store';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
// import DelayLink from '../DelayLink/DelayLink';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    menuTooltip: {
        whiteSpace: 'nowrap'
    },
    menuIcon: {
        '& div[role="link"]': {
            alignItems: 'center',
            display: 'grid',
        },
    },
}));

const Menu = ({ menuOpen, setMenuOpen, changeBranchType, branchType }) => {
    let classes = useStyles();

    // const handleMenuOpen = () => {
    //     setMenuOpen(true);
    // };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    const changeBranchHandler = (to) => {
        if (branchType !== to) {
            changeBranchType();
            return true;
        }

        return false;
    };

    const menuActions = [
        { icon: <Link to="/flavor" onClick={() => { changeBranchHandler('flavor') }}> <RestaurantIcon style={{ color: branchType === "flavor" ? 'blue' : 'grey' }} /> </Link>, name: 'חבר טעמים' },
        { icon: <Link to="/yellow" onClick={() => { changeBranchHandler('yellow') }}> <StoreIcon style={{ color: branchType === "yellow" ? '#8c8c00' : 'grey' }} /> </Link>, name: 'חבר של קבע' },
        { icon: <Link to="/ticketStations" onClick={() => { changeBranchHandler('ticketStations') }}> <ConfirmationNumberIcon style={{ color: branchType === "ticketStations" ? 'orange' : 'grey' }} /> </Link>, name: 'עמדות הוצאת כרטיסים' },
    ];

    return (
        <SpeedDial
            style={{ float: 'right', paddingRight: '1em', paddingBottom: '1em', zIndex: '2' }}
            ariaLabel="SpeedDial tooltip example"
            icon={<LayersIcon />}
            // openIcon={<CloseIcon />}
            // onClose={handleMenuClose}
            // onOpen={handleMenuOpen}
            open={menuOpen}
            FabProps={{ onClick: () => setMenuOpen(!menuOpen) }}
        >
            {menuActions.map(action => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    tooltipPlacement={'left'}
                    onClick={() => { action.onClick && action.onClick(); handleMenuClose(); return true; }}
                    classes={{ staticTooltip: classes.menuTooltip, fab: classes.menuIcon }}
                />
            ))}
        </SpeedDial>
    );
};

export default Menu;