import { useEffect, useState, useRef, useCallback } from 'react';

const usePosition = (updateThreshold = 1000, ageThreshold = 10000) => {
    const [currentPosition, setCurrentPosition] = useState(null);
    const [positionOutdated, setPositionOutdated] = useState(null);
    const thresholdTimeout = useRef(null);

    const positionChangedHandler = useCallback((pos) => {
        if (currentPosition && Date.now() - currentPosition.timestamp < updateThreshold)
            return;

        setCurrentPosition(pos);
        if (positionOutdated || positionOutdated == null)
            setPositionOutdated(false);

        if (thresholdTimeout.current)
            clearTimeout(thresholdTimeout.current);

        thresholdTimeout.current = setTimeout(() => {
            setPositionOutdated(true);
        }, ageThreshold);
    }, [ageThreshold, positionOutdated, currentPosition, updateThreshold]);

    useEffect(() => {
        let watchId;
        if (navigator.geolocation) {
            watchId = navigator.geolocation.watchPosition(positionChangedHandler, (posError) => {
                console.log(posError);
            });
        }

        if (watchId)
            return () => {
                navigator.geolocation.clearWatch(watchId);
                if (thresholdTimeout.current)
                    clearTimeout(thresholdTimeout.current);
            };

        return () => { };
    }, [positionChangedHandler]);

    if (currentPosition)
        return [currentPosition.coords, currentPosition.timestamp, positionOutdated];

    return [null, null, null];
};

export default usePosition;