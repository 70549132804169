import React, { useRef, useState } from 'react';
import { Redirect } from 'react-router';

import MainApp from '../MainApp/MainApp';

import Axios from 'axios';
import { Grid, Cell } from 'styled-css-grid';
import { Button, TextField } from '@material-ui/core';

import "./AuthCheck.css"

const PasswordForm = () => {
    const [showPasswordMsg, setShowPasswordMsg] = useState();
    const passRef = useRef();

    const submitForm = (e) => {
        e.preventDefault();
        console.log('form submitted');
        console.log('test');

        let password = passRef.current.value;
        Axios.post(process.env.REACT_APP_API_URL + "/auth/password", { username: 'guest', password }, { withCredentials: true }).then((res) => {
            if (res.status === 200)
                return res.data;

            return null;
        }, (err) => {
            setShowPasswordMsg(true);
            return;
        }).then((jsonData) => {
            if (!jsonData)
                return;

            sessionStorage.setItem('jwt', jsonData.jwt);
            window.location.reload();
        });
    };

    return (
        <Grid style={{ height: '100%' }} rows="1fr auto auto auto 1fr" columns="1fr auto 1fr" areas={[". . .", ". google .", ". divider .", ". password .", ". . ."]}>
            <Cell area="google">
                <a href={`${process.env.REACT_APP_API_URL}/auth/google/callback`}> <img alt="Sign in with google" src="/googleSignin/light-normal.png" /> </a>
            </Cell>
            <Cell area="divider">
                <hr />
            </Cell>
            <Cell area="password">
                or enter a password:
                <br />
                <form onSubmit={submitForm}>
                    <TextField label="Password" inputRef={passRef} type="password" />
                    {
                        showPasswordMsg ? (
                            <div>
                                Wrong password...
                            </div>
                        ) : null
                    }
                    <br />
                    <Button type="submit" value="submit" variant="outlined" style={{ marginTop: '1em' }}> Submit </Button>
                </form>
            </Cell>
        </Grid>
    );
}

const AuthCheck = ({ location: { search = "" } }) => {
    let save = /\?JWT=.*/.test(search);

    if (!save) {
        if (!sessionStorage.getItem('jwt')) {
            // window.location = `${process.env.REACT_APP_API_URL}/auth/google/callback`;
            return <PasswordForm />;
        }
    }
    else {
        let token = search.substring(search.indexOf("=") + 1, search.length);
        sessionStorage.setItem('jwt', token);
        return <Redirect to="/" />
    }

    return <MainApp />;
};

export default AuthCheck;