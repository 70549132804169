import React, { useEffect } from 'react';

import { disableBodyScroll/*, enableBodyScroll, clearAllBodyScrollLocks*/ } from 'body-scroll-lock';

import MapPage from '../MapPage/MapPage';
import { Provider } from 'react-redux';
import store from '../../redux/Store';

import {
    BrowserRouter as Router,
    // Router,
    Route,
    Switch,
    // Link,
    // useParams
} from "react-router-dom";

function MainApp() {
    useEffect(() => {
        disableBodyScroll(document.querySelector('body'));
    }, []);

    return (
        <Provider store={store}>
            <Router>
                <Switch>
                    <Route exact path="/info/:section?" component={MapPage} />
                    <Route exact path="/:branchType?/:place_id?" component={MapPage} />
                    {/* <Route path="" component={MapPage} /> */}
                    {/* <Route exact path="/info/:infoType?" component={MapPage} /> */}
                    {/* <Route exact path="/contactUs" component={MapPage} /> */}
                </Switch>
            </Router>
        </Provider>
    );
}

export default MainApp;