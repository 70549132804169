import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import monitorReducersEnhancer from './enhancers/monitorReducers'; 
// import loggerMiddleware from './middleware/logger';
import rootReducer from './Reducers';

let preloadedState = {};

const store = configureStore({ 
   reducer: rootReducer, 
   middleware: [...getDefaultMiddleware()],
   preloadedState, 
//    enhancers: [monitorReducersEnhancer],
});

export default store;