/* eslint-disable no-extend-native */
Number.prototype.toRadians = function() { return this * Math.PI / 180; };
Number.prototype.toDegrees = function() { return this * 180 / Math.PI; };

export const getDistance = ({ lat: lat1, lng: lon1 }, { lat: lat2, lng: lon2 }) => {
    var φ1 = lat1.toRadians(), φ2 = lat2.toRadians(), Δλ = (lon2 - lon1).toRadians(), R = 6371e3; // gives d in metres
    var d = Math.acos(Math.sin(φ1) * Math.sin(φ2) + Math.cos(φ1) * Math.cos(φ2) * Math.cos(Δλ)) * R;
    return d;
};

function round_to_precision(x, precision) {
    var y = +x + (precision === undefined ? 0.5 : precision/2);
    return y - (y % (precision === undefined ? 1 : +precision));
}

export const humanizeDistance = (d) => {
    d = Math.round(d);
    if (d > 1000)
        return round_to_precision(d / 1000, 0.1).toFixed(1) + ' ק"מ';

    return d + " מ'"
};