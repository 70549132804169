import React from 'react';

export const Thanks = () => {
    return (
        <div style={{ direction: 'rtl', textAlign: 'initial' }}>
            תודה לאנשים שעזרו להשיק את האתר, כל אחד תרם בדרכו ועזר להביא לכם את השירות הקיים כיום:
            <ul>
                <li>
                    עדי מירז
                </li>
                <li>
                    עידו ביידה
                </li>
                <li>
                    איתי לבנה
                </li>
                <li>
                    שקד בוכבינדר
                </li>
                <li>
                    אבי סלמה
                </li>
                <li>
                    נופר פיטרמן
                </li>
                <li>
                    שלי נגר
                </li>
                <li>
                    גיא בן דוד
                </li>
            </ul>
        </div>
    );
};