import React, { useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Grid } from 'styled-css-grid';

import Fab from '@material-ui/core/Fab';

import DirectionsIcon from '@material-ui/icons/Directions';
import CallIcon from '@material-ui/icons/Call';
import ShareIcon from '@material-ui/icons/Share';
import MapIcon from '@material-ui/icons/Map';
import { stopPropagatingMouseTouch } from '../../../../utils/events';

import { useDrag } from 'react-use-gesture';
import { trackEvent } from '../../../../utils/matomo';
import { getDisplayName } from '../../../../utils/branch';

const useStyles = makeStyles(theme => ({
    scrollContainer: {
        width: '100%',
        height: '100%',
        overflowX: 'scroll',
        '&::-webkit-scrollbar': {
            width: '0 !important',
            display: 'none',
        },
        scrollbarWidth: 'none', /* Firefox */
        '& a:-webkit-any-link': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
    actionsRoot: {
        color: '#3f51b5',
        background: 'white',
        border: '1px solid',
        '&:hover': {
            background: '#3f51b552',
        },
    },
}));

const BasicActions = ({ branch, branchType, shareBranch }) => {
    const scrollContainerRef = useRef();
    const bind = useDrag(({ delta: [mx, my], tap, touches, buttons }) => {
        // if (mx > 200) cancel()
        // setDeltaY(my);
        // setDown(down);

        // if (tap && infoWindowState < 2 && mx < 10 && deltaY < 10)
        //     setInfoWindowState(infoWindowState + 1);

        // if (!last)
        //     return;

        // if (-deltaY > 100) {
        //     if (infoWindowState < 3)
        //         setInfoWindowState(infoWindowState + 1);
        // }
        // if (-deltaY < -100) {
        //     if (infoWindowState > 0)
        //         setInfoWindowState(infoWindowState - 1);
        // }
        // if (-deltaY < 0 && infoWindowState === 0)
        //     setSelectedBranch(null);

        // setDeltaY(0);
        if (!scrollContainerRef.current || tap || (touches !== 1 && buttons !== 1))
            return;

        scrollContainerRef.current.scrollBy(-mx, 0);
    }, {
        axis: 'x'
    });

    let displayName = getDisplayName(branch, branchType);

    const classes = useStyles();
    
    return (
        <div {...bind()} ref={scrollContainerRef} className={classes.scrollContainer} {...stopPropagatingMouseTouch()}>
            <Grid rows="1fr" columns="auto" flow="column" style={{ paddingRight: '1em', paddingLeft: '1em', width: 'max-content' }}>
                {/* <a href={`https://www.google.com/maps/dir/?api=1&destination=${displayName}&destination_place_id=${branch.place_id}`} target="_blank" rel="noopener noreferrer"> */}
                <a href={`https://waze.com/ul?ll=${branch.lat},${branch.lng}&navigate=yes`} target="_blank" rel="noopener noreferrer">
                    <Fab key="directions" size="small" color="primary" variant="extended"> <DirectionsIcon style={{ paddingRight: '8px' }} />
                        Directions
                    </Fab>
                </a>
                {
                    [(disabled) =>
                        <Fab key="call" disabled={disabled} classes={{ root: classes.actionsRoot }} size="small" color="primary" variant="extended" onClick={() => trackEvent('Marker', 'Call', displayName)}>
                            <CallIcon style={{ paddingRight: '8px' }} />
                            Call
                        </Fab>
                    ].map((e) =>
                        branch.phone ? (
                            <a key="phone" href={`tel:${branch.phone}`}>
                                {e()}
                            </a>
                        ) : e(true)
                    )
                }
                <Fab key="share" onClick={shareBranch} disabled={branchType === "ticketStations"} classes={{ root: classes.actionsRoot }} size="small" color="primary" variant="extended">
                    <ShareIcon style={{ paddingRight: '8px' }} />
                    Share
                </Fab>
                {
                    [(disabled) =>
                        <Fab key="opengoogle" disabled={disabled} classes={{ root: classes.actionsRoot }} size="small" color="primary" variant="extended" onClick={() => trackEvent('Marker', 'Open website', displayName)}>
                            <MapIcon style={{ paddingRight: '8px' }} />
                            Open in google
                        </Fab>
                    ].map((e) =>
                        branchType !== "ticketStations" && branch.place_id ? (
                            <a key={"googleUrl"} href={`https://www.google.com/maps/search/?api=1&query=${branch.address}&query_place_id=${branch.place_id}`} target="_blank" rel="noopener noreferrer">
                                {e()}
                            </a>
                        ) : e(true)
                    )
                }
            </Grid>
        </div>
    );
};

export default BasicActions;