import React, { useState } from 'react';

import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Cell } from 'styled-css-grid';

import { isMobile } from "react-device-detect";

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import moment from 'moment';

import RefreshIcon from '@material-ui/icons/Refresh';
// import NearMeIcon from '@material-ui/icons/NearMe';
import InfoIcon from '@material-ui/icons/Info';
import SettingsIcon from '@material-ui/icons/Settings';
import CommentIcon from '@material-ui/icons/Comment';
import CloseIcon from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PolicyIcon from '@material-ui/icons/Policy';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy';
import { TermsOfUse } from './components/TermsOfUse/TermsOfUse';
import { Thanks } from './components/Thanks/Thanks';
import { About } from './components/About/About';
import { trackEvent } from '../../utils/matomo';
import ContactUs from './components/ContactUs/ContactUs';

const useStyles = makeStyles(theme => ({
    backDrop: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: ({ open }) => open ? '100%' : '0px',
        height: '100%',
        zIndex: '3',
        background: '#3338',
    },
    mainMenuContainer: {
        position: 'absolute',
        top: '0',
        right: '0',
        height: '100%',
        width: ({ open }) => open ? '100%' : '0px',
        zIndex: '3',
        transition: 'all 0.3s ease',
        overflow: 'hidden',
    },
    mainMenuPanel: {
        height: '100%',
        width: '100%',
        zIndex: '4',
        background: 'white',
        textAlign: 'right',
        paddingTop: '1.5em',
        boxSizing: 'border-box',
        '& a:-webkit-any-link': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& > :nth-child(1)': {
            borderBottom: '1px solid #9999993d',
        },
    },
    mainMenuPanelContent: {
        height: '100%',
        '& > :nth-child(2)': {
            borderTop: '1px solid #9999993d',
        },
    },
    drawerPaper: {
        width: ({ isMobile, openSection }) => isMobile ? (openSection ? '100%' : '90%') : '30%',
    },
    menuOption: {
        height: '100%',
        '&:hover': {
            background: '#9999993d',
        },
    },
    logoCell: {
        position: 'sticky',
        top: '0',
    },
}));

const MenuOption = ({ close, icon, label, optionAction, optionLink, optionSection, setOpenSection }) => {
    let classes = useStyles();

    let onOptionClick = () => {
        if (optionAction) {
            optionAction();
            close();
        }

        if (optionSection) {
            trackEvent('Main Menu', 'Button Click', optionSection);
            setOpenSection(optionSection);
        }
    };

    let option = (
        <Grid className={classes.menuOption + " pointer"} rows="1fr" columns="1fr 2em" onClick={onOptionClick}>
            <Cell style={{ alignItems: 'center', display: 'grid' }}>
                {label}
            </Cell>
            <Cell style={{ alignItems: 'center', display: 'grid', justifyItems: 'end' }}>
                {icon}
            </Cell>
        </Grid>
    );

    if (optionLink)
        return (
            <Link to={optionLink}>
                {option}
            </Link>
        );

    return option;
};

const MainMenuSections = {
    about: <About />,
    termsOfUse: <TermsOfUse />,
    privacyPolicy: <PrivacyPolicy />,
    thanks: <Thanks />,
    contactUs: <ContactUs />,
};

const MainMenu = ({ open, close, refreshHandler, centerCurrentLocation, openInfoWindow }) => {
    let { section } = useParams();

    let [openSection, setOpenSection] = useState(section);

    let classes = useStyles({ open, isMobile, openSection });

    let closeMainMenu = () => {
        setOpenSection(null);
        close();
    }

    let closeSection = () => {
        setOpenSection(null);
    }

    let menuOptions = [
        {
            icon: <RefreshIcon />,
            label: `רענון (שונה לאחרונה ב: ${moment(parseInt(localStorage.getItem(`flavor.changedAt`))).format('DD/MM/yyyy,hh:mm')})`,
            optionAction: refreshHandler,
        },
        // {
        //     icon: <SettingsIcon />,
        //     label: 'הגדרות',
        //     // optionLink: '/info/settings',
        //     optionSection: 'settings',
        // },
    ];

    let menuFooterOptions = [
        {
            icon: <InfoIcon />,
            label: 'אודות',
            optionSection: 'about',
        },
        {
            icon: <SupervisorAccountIcon />,
            label: 'תנאי שימוש',
            optionSection: 'termsOfUse',
        },
        {
            icon: <PolicyIcon />,
            label: 'מדיניות פרטיות',
            optionSection: 'privacyPolicy',
        },
        {
            icon: <PeopleAltIcon />,
            label: 'תודות',
            optionSection: 'thanks',
        },
        {
            icon: <CommentIcon />,
            label: 'צור קשר',
            optionSection: 'contactUs',
        },
    ];

    return (
        <>
            <div className={classes.backDrop} onClick={closeMainMenu} />
            <div className={classes.mainMenuContainer}>
                <SwipeableDrawer
                    anchor="right"
                    open={open}
                    onClose={closeMainMenu}
                    onOpen={() => { }}
                    classes={{ paper: classes.drawerPaper }}
                >
                    <Grid gap="0" className={classes.mainMenuPanel} columns="1fr" /*rows="repeat(auto-fit, 3em)"*/ rows="4em 1fr">
                        <Cell className={classes.logoCell} style={{ alignItems: 'center', display: 'grid', gridTemplateRows: '100%' }}>
                            <Grid columns="auto 1fr auto" style={{ height: '100%' }}>
                                <Cell>
                                    <CloseIcon onClick={closeMainMenu} className="pointer" style={{ paddingLeft: '0.5em' }} />
                                </Cell>
                                <Cell>
                                    <img alt="logo" src="/logo-black.png" style={{ height: '100%', width: '100%' }} />
                                </Cell>
                                <Cell>
                                    {
                                        openSection ?
                                            <ChevronRightIcon onClick={closeSection} className="pointer" style={{ paddingRight: '0.5em' }} />
                                            : null
                                    }
                                </Cell>
                            </Grid>
                        </Cell>
                        <Cell style={{ overflowY: 'auto', marginRight: '1em' }}>
                            <Grid gap="0" rows="repeat(auto-fit, 3em)" columns="1fr" className={classes.mainMenuPanelContent}>
                                {
                                    openSection ? (
                                        MainMenuSections[openSection]
                                    ) : (
                                            <>
                                                {menuOptions.map((o) => <MenuOption key={o.label} {...o} close={closeMainMenu} setOpenSection={setOpenSection} />)}
                                                {menuFooterOptions.map((o) => <MenuOption key={o.label} {...o} close={closeMainMenu} setOpenSection={setOpenSection} />)}
                                                {/* <Cell style={{ alignItems: 'center', display: 'grid' }}>
                                                    <a href="mailto:support@mail.shopndine.ofirl.com" style={{ height: '100%' }}>
                                                        <MenuOption key="contactUs" icon={<CommentIcon />} label="צור קשר" close={closeMainMenu} />
                                                    </a>
                                                </Cell> */}
                                            </>
                                        )
                                }
                            </Grid>
                        </Cell>
                    </Grid>
                </SwipeableDrawer>
            </div>
        </>
    );
};

export default MainMenu;