import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
    alertIcon: {
        marginLeft: '12px',
        marginRight: 'unset'
    },
    alertAction: {
        paddingLeft: 'unset',
        paddingRight: '16px',
    },
}));

const Alert = (props) => {
    let classes = useStyles();

    return <MuiAlert classes={{ icon: classes.alertIcon, action: classes.alertAction }} elevation={6} variant="filled" {...props} />;
};

export default Alert;