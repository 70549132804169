import React, { useState, useMemo, useCallback, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { Grid, Cell } from 'styled-css-grid';
import { getDisplayName, getDescription } from '../../utils/branch';
import { getDistance, humanizeDistance } from '../../utils/coordinates';

import Rating from '@material-ui/lab/Rating';

import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import BasicActions from './components/BasicActions/BasicActions';
import Overview from './components/Overview/Overview';
import { trackEvent } from '../../utils/matomo';

const useStyles = makeStyles(theme => ({
    bottomBarGrid: {
        borderRadius: ({ infoWindowState }) => infoWindowState < 2 ? '1em 1em 0 0' : null,
        float: 'right',
        height: '100%',
        width: '100%',
        background: 'white',
    },
    detailsGrid: {
        textAlign: 'right',
        paddingRight: '8px',
        paddingLeft: '8px',
        '& > *': {
            fontSize: '0.8em',
        },
        '& > :nth-child(1)': {
            fontSize: '1em',
        },
    },
}));

const BottomBar = ({ branch: selectedBranch, branchType, currentLocation, infoWindowState }) => {
    const [selectedBranchIndex, setSelectedBranchIndex] = useState(0);

    const classes = useStyles({ infoWindowState });

    // console.log(selectedBranch);
    let branch = useMemo(() => {
        if (selectedBranch)
            return selectedBranch.branches[selectedBranchIndex];

        return null;
    }, [selectedBranch, selectedBranchIndex]
    );

    let changeBranch = useCallback((num) => {
        let newSelectedBranchIndex = selectedBranchIndex + num;
        if (newSelectedBranchIndex >= 0 && newSelectedBranchIndex < selectedBranch.branches.length)
            setSelectedBranchIndex(selectedBranchIndex + num);
    }, [selectedBranch, selectedBranchIndex]);

    useEffect(() => {
        if (branch)
            trackEvent('Marker', 'Show info window', getDisplayName(branch, branchType));
    }, [branch, branchType]);

    if (branch == null) {
        if (selectedBranchIndex !== 0)
            setSelectedBranchIndex(0);

        return null;
    }

    let displayName = getDisplayName(branch, branchType);
    let branchDescription = getDescription(branch, branchType);

    let currentDay = new Date(Date.now()).getDay();

    if (currentLocation) {
        currentLocation.lat = currentLocation.latitude;
        currentLocation.lng = currentLocation.longitude;
    }

    let shareBranch = () => {
        if (navigator.share) {
            trackEvent('Marker', 'Share', displayName)
            navigator.share({
                title: 'ShopN\'Dine Share',
                url: `https://shopndine.ofirl.com/${branchType}/${branch.place_id}`
            })
        }
    };

    return (
        // photos row : ${infoWindowState === 0 || infoWindowState === 3 ? '0px' : 'minmax(100px, 200px)'}
        // also add photos to areas
        < Grid className={classes.bottomBarGrid} rows={`auto ${infoWindowState < 2 ? '5fr' : 'auto'} ${infoWindowState < 2 ? '3em' : '0px'} ${infoWindowState >= 2 ? '1fr' : '0px'} 0px`}
            columns="1fr" areas={["branchSelect", "details", "actions", "extendedDetails", "spacer"]} >
            {
                selectedBranch.branches.length > 1 ? (
                    <Cell area="branchSelect">
                        <Grid columns="auto 1fr auto 1fr auto" rows="1fr" areas={["previous . current . next"]}>
                            <Cell area="previous">
                                <NavigateBeforeIcon onClick={(e) => { e.preventDefault(); changeBranch(-1) }} />
                            </Cell>
                            <Cell area="current">
                                {selectedBranchIndex + 1}/{selectedBranch.branches.length}
                            </Cell>
                            <Cell area="next">
                                <NavigateNextIcon onClick={(e) => { e.preventDefault(); changeBranch(1) }} />
                            </Cell>
                        </Grid>
                    </Cell>
                ) : null
            }
            {/* <Cell area="photos" style={{ background: 'grey' }}>
                Photos...
            </Cell> */}
            <Cell area="details">
                <Grid className={classes.detailsGrid} gap="3px" rows="auto auto auto auto auto" columns="1fr" areas={["name", "description", "rating", "type", "open"]}>
                    <Cell area="name" style={{ fontWeight: '500' }}>
                        {branchType !== "ticketStations" ? displayName : branch.city}
                    </Cell>
                    <Cell area="description">
                        {branchType !== "ticketStations" ? branchDescription : branch.desc}
                        {branch.kosher ? ", " + branch.kosher : null}
                    </Cell>
                    <Cell area="rating" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {
                            branchType !== "ticketStations" ? (
                                branch.user_ratings_total ? (
                                    <>
                                        ({branch.user_ratings_total})
                                    <Rating style={{ direction: 'rtl', fontSize: '1.5em' }} value={branch.rating} precision={0.5} readOnly />
                                        <span> {branch.rating} </span>
                                    </>
                                ) : 'לא קיימים דירוגים'
                            ) : null
                        }
                    </Cell>
                    <Cell area="type" style={{ direction: 'rtl' }}>
                        {branch.category} {branch.price_level != null ? ` · ${branch.price_level > 0 ? "₪".repeat(branch.price_level) : 'חינם'}` : null} {currentLocation ? ' · ' + humanizeDistance(getDistance(currentLocation, branch)) : null}
                    </Cell>
                    <Cell area="open">
                        {branchType === "ticketStations" ? branch.hours : currentDay < 6 ? branch.hours15 : (currentDay < 7 ? branch.hours6 : branch.hours7)}
                    </Cell>
                </Grid>
            </Cell>
            <Cell area="actions">
                {
                    infoWindowState < 2 ? (
                        <BasicActions branch={branch} branchType={branchType} shareBranch={shareBranch} />
                    ) : (
                            null
                        )
                }
            </Cell>
            <Cell area="extendedDetails">
                {
                    infoWindowState >= 2 ? (
                        <Overview branch={branch} branchType={branchType} shareBranch={shareBranch} />
                    ) : null
                }
            </Cell>
        </Grid >
    );
};

export default BottomBar;