import Axios from "axios";

export const getBranches = async (branchType) => {
    try {
        let resposne = await Axios.get(process.env.REACT_APP_API_URL + `/scraper/${branchType}/getBranches`, { withCredentials: true });
        return resposne.status === 200 ? resposne.data.result : null;
    }
    catch (e) {
        return false;
    }
};

export const getLastUpdate = async () => {
    try {
        return await Axios.get(process.env.REACT_APP_API_URL + `/scraper/lastUpdate`, { withCredentials: true });
    }
    catch (e) {
        return false;
    }
};

export const getChangedAt = async (branchType) => {
    try {
        return await Axios.get(process.env.REACT_APP_API_URL + `/scraper/${branchType}/changedAt`, { withCredentials: true });
    }
    catch (e) {
        return false;
    }
};

export const getRefreshStatus = async (branchType) => {
    try {
        let response = await Axios.get(process.env.REACT_APP_API_URL + `/scraper/${branchType}/refreshStatus`, { withCredentials: true });
        return response.status === 200 && response.data.result;
    }
    catch (e) {
        return false;
    }
};

export const updateBranches = async (branchType) => {
    try {
        return await Axios.get(process.env.REACT_APP_API_URL + `/scraper/${branchType}/refresh`, { withCredentials: true });
    }
    catch (e) {
        return false;
    }
};

export const sendContactForm = async (form) => {
    try {
        return await Axios.post(process.env.REACT_APP_API_URL + `/submitContactForm`, form, { withCredentials: true });
    }
    catch (e) {
        return false;
    }
};

export const sendSuggestionForm = async (form) => {
    try {
        return await Axios.post(process.env.REACT_APP_API_URL + `/submitSuggestionForm`, form, { withCredentials: true });
    }
    catch (e) {
        return false;
    }
};