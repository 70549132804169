import React from 'react';

export const PrivacyPolicy = () => {
    return (
        <div style={{ direction: 'rtl', textAlign: 'initial', fontFamily: 'Roboto,arial,sans-serif', fontWeight: '400', fontSize: '14px' }}>
            <h2> אם בחרת להשתמש בשירותים שלנו, המשמעות היא שנתת בנו אמון לטפל באופן הולם במידע שמסרת לנו.אנחנו מבינים שמדובר באחריות גדולה ואנחנו עושים מאמצים רבים כדי להגן על המידע שלך ולתת לך שליטה. </h2>
            <br />
            מדיניות הפרטיות הזו נועדה לעזור לך להבין איזה מידע אנו אוספים ומדוע אנחנו אוספים אותו.

            <h3> אנחנו רוצים להבהיר עבורך אילו סוגי מידע אנחנו אוספים כשנעשה שימוש בשירותים שלנו </h3>
            <br />
            אנו אוספים מידע כדי לספק שירותים טובים יותר לכל המשתמשים שלנו – החל מפרטים בסיסיים כמו השפה שלך, ועד פרטים מורכבים יותר כמו המקומות שהיו השימושיים ביותר עבורך או החיפושים שחוזרים על עצמם. המידע ש-Shop N' Dine אוספת והאופן שבו נעשה בו שימוש תלויים באופן השימוש שלך בשירותים שלנו.
            <br /><br />
            אם לא התחברת לחשבון Shop N' Dine, המידע שאנחנו אוספים מאוחסן יחד עם מזהים ייחודיים הקשורים לדפדפן, לאפליקציה או למכשיר שברשותך. כך אנחנו יכולים לעשות דברים כמו לשמור על העדפות השפה שלך בכל הפעלה של הדפדפן.
            <br /><br />
            אם התחברת לחשבון, אנחנו גם אוספים מידע שמאוחסן בחשבון Shop N' Dine שלך, והוא מטופל כמו מידע אישי (מידע אשר ניתן לזהות אותך איתו).


            <h3> דברים שיצרת או סיפקת לנו </h3>
            <br /><br />
            בעת היצירה של חשבון Shop N' Dine אנחנו מקבלים ממך מידע אישי שכולל את שמך ואת הסיסמה שלך. גם אם לא נכנסת לחשבון Shop N' Dine, עדיין ייתכן שנקבל ממך מידע, כמו כתובת אימייל שאליה נשלח עדכונים לגבי השירותים שלנו.
            <br />
            בנוסף אנחנו אוספים תוכן שיצרת, העלית או קיבלת מאחרים בזמן השימוש בשירותים שלנו. תוכן כזה כולל דברים כמו תגובות שהוספת למקומות.

            <h3> המידע שנאסף בזמן השימוש בשירותים שלנו </h3>
            <br />
            <h4> האפליקציות, הדפדפנים והמכשירים שלך </h4>
            <br />

            אנחנו אוספים מידע לגבי האפליקציות, הדפדפנים והמכשירים שבהם נעשה שימוש כדי לגשת אל שירותי Shop N' Dine. מידע זה עוזר לנו לספק תכונות כמו עדכוני מוצר אוטומטיים ועמעום המסך כשהסוללה חלשה.
            <br /><br />
            המידע שאנחנו אוספים כולל מזהים ייחודיים, את סוג הדפדפן וההגדרות שלו, את סוג המכשיר וההגדרות שלו, מערכת ההפעלה, פרטים לגבי הרשת הסלולרית, כולל שם ספק השירות ומספר הטלפון, כמו גם מספר גירסת האפליקציה. אנחנו גם אוספים מידע על האינטראקציה עם השירותים שלנו באפליקציות, בדפדפנים ובמכשירים שברשותך, כולל כתובת IP, דוחות קריסה ופעילות במערכת. בנוסף, אנחנו אוספים מידע על הבקשות שלך: התאריך והשעה שבהם נשלחו, כמו גם כתובת האתר של הגורם המפנה.
            <br /><br />
            אנחנו אוספים את המידע הזה כששירות Shop N' Dine במכשיר שלך יוצר קשר עם השרת שלנו — לדוגמה, כששירות מסוים מחפש עדכונים אוטומטיים.

          <h4> הפעילות שלך </h4>
            <br />
            אנחנו אוספים מידע לגבי הפעילות שלך בשירותים שלנו. המידע הזה משמש אותנו כדי לעשות דברים כמו להמליץ על מקום שאולי ימצא חן בעיניך. המידע שאנחנו אוספים לגבי פעילות עשוי לכלול:
            <ul>
                <li>
                    מונחים שחיפשת
                </li>
                <li>
                    צפיות ואינטראקציות עם תוכן ומודעות
                </li>
            </ul>


            <h4> פרטי המיקום שלך </h4>
            <br />
            אנחנו אוספים מידע לגבי המיקום שלך בעת השימוש בשירותים שלנו. כך אנחנו יכולים להציע תכונות כמו מסלולי נסיעה בדרך לחופשה או זמני הקרנה של סרטים בבתי קולנוע בקרבתך.
            <br /><br />
            את המיקום שלך אפשר לקבוע ברמות דיוק שונות בעזרת:
            <ul>
                <li>
                    GPS
                </li>
                <li>
                    כתובת IP
                </li>
                <li>
                    נתוני חיישנים מהמכשיר
                </li>
                <li>
                    מידע על דברים בקרבת המכשיר שלך, כמו נקודות גישה אל Wi-Fi, אנטנות סלולריות ומכשירים עם קישוריות Bluetooth
                </li>
            </ul>

            הסוגים של נתוני המיקום שאנחנו אוספים תלויים באופן חלקי בהגדרות המכשיר והחשבון שלך. לדוגמה, אפשר להפעיל או להשבית את המיקום של מכשיר ה-Android בעזרת אפליקציית ההגדרות של המכשיר. אפשר גם להפעיל את היסטוריית המיקומים אם ברצונך ליצור מפה פרטית של המקומות שאליהם הגעת.
            <br /><br /><br />

            אנחנו משתמשים במגוון טכנולוגיות כדי לאסוף ולאחסן מידע, כולל קובצי cookie, תגי פיקסל, אחסון מקומי, כמו אחסון אינטרנט של דפדפן או מטמונים של נתוני אפליקציות, מסדי נתוני ויומני שרתים.


            <h3> אנחנו משתמשים בנתונים כדי לשפר את השירותים שלנו </h3>
            <br />
            אנחנו משתמשים במידע שאנחנו אוספים מכל השירותים שלנו כדי:
            <h4> לספק את השירותים שלנו </h4>
            <br />
            אנחנו משתמשים במידע שלך כדי לספק את השירותים שלנו, כמו עיבוד המונחים בחיפושים כדי להחזיר תוצאות.
            <h4> לתחזק ולשפר את השירותים שלנו </h4>
            <br />
            אנחנו משתמשים במידע שלך גם כדי לוודא שהשירותים שלנו פועלים כראוי. למשל, כדי לעקוב אחר הפסקות שירות או כדי לפתור בעיות שדיווחת לנו עליהן. בנוסף, אנחנו משתמשים במידע כדי לשפר את השירותים שלנו — לדוגמה, אם אנחנו יכולים להבין מהם המקמות שמשתמשים מחפשים בתדירות הכי גבוהה, זה מאפשר לנו להציג אותם בסדר הפופולריות שלהם.
            <h4> לפתח שירותים חדשים </h4>
            <br />
            המידע שנאסף בשירותים קיימים מאפשר לנו לפתח שירותים חדשים.

            <h4> לספק שירותים בהתאמה אישית, כולל תוכן ומודעות </h4>
            <br />
            אנחנו משתמשים במידע שנאסף כדי להתאים אישית את השירותים שלנו עבורך, כולל הצגת המלצות, תוכן מותאם אישית ותוצאות חיפוש המותאמות לך.

            <h4> מדידת ביצועים </h4>
            <br />
            אנחנו משתמשים בנתונים לביצוע ניתוחים ומדידה כדי להבין באיזה אופן נעשה שימוש בשירותים שלנו. לדוגמה, אנחנו מנתחים נתונים לגבי הכניסות שלך לאתרים שלנו כדי לבצע דברים כמו שיפור העיצוב של מוצרים.

            <h4> שמירה על קשר איתך </h4>
            <br />
            אנחנו משתמשים במידע שאנחנו אוספים, כמו כתובת האימייל שלך, כדי לפנות אליך ישירות. לדוגמה, אנחנו עשויים לשלוח לך הודעה אם נזהה פעילות חשודה, כמו ניסיון להיכנס אל חשבון Shop N' Dine שלך ממיקום חריג. אנחנו גם עשויים ליידע אותך לגבי שינויים או שיפורים שיבוצעו בקרוב בשירותים שלנו. בנוסף, אחרי פנייה שלך אל Shop N' Dine, נשמור רשומה של הבקשה שלך כדי לעזור בפתרון בעיות שבהן נתקלת.

            <br /><br /><br />
            אנחנו משתמשים בטכנולוגיות שונות כדי לעבד את המידע שלך למטרות אלה. אנחנו משתמשים במערכות אוטומטיות שמנתחות את התוכן שלך על מנת לספק לך דברים כמו תוצאות חיפוש מותאמות אישית


            <h4> כשהמידע משותף על-ידי Shop N' Dine </h4>
            <br />

            איננו משתפים מידע אישי עם חברות, ארגונים או אנשים מחוץ ל-Shop N' Dine, למעט במקרים הבאים:
            <h5> קיבלנו את הסכמתך </h5>
            <br />
            נשתף מידע אישי מחוץ ל-Shop N' Dine אם נתת את הסכמתך.

            <h5> לצורך עיבוד חיצוני </h5>
            <br />
            אנו מספקים מידע אישי לתאגידים הקשורים אלינו ולעסקים או לאנשים אחרים שאנו נותנים בהם אמון כדי שיעבדו אותו עבורנו, לפי ההוראות שלנו ובאופן העולה בקנה אחד עם מדיניות הפרטיות שלנו, תוך שימוש בכל האמצעים ההולמים לשמירה על סודיות ואבטחה.

            <h5> מטעמים משפטיים </h5>
            <br />
            אנו נשתף פרטים אישיים מחוץ ל-Shop N' Dine אם אנו סבורים כי באמת יש צורך סביר לאפשר את הגישה לפרטים, את השימוש בהם, את שמירתם או חשיפתם על מנת:
            <ul>
                <li>
                    לציית לדין החל, לתקנה, להליך משפטי או לבקשת ממשל הניתנת לאכיפה. בדוח השקיפות שלנו אנחנו משתפים מידע לגבי המספר וסוג הבקשות שאנחנו מקבלים מממשלות.
                </li>
                <li>
                    לאכוף תנאי שימוש חלים, לרבות חקירת האפשרות שבוצעו הפרות.
                </li>
                <li>
                    לזהות או למנוע תרמיות, בעיות אבטחה או בעיות טכניות, או לטפל בהן בכל צורה אחרת.
                </li>
                <li>
                    להגן מפני פגיעה בזכויות, בקניין או בבטיחות של Shop N' Dine, של המשתמשים שלנו או של הציבור, ככל שהדין דורש או מתיר זאת.
                </li>
            </ul>

            אנו עשויים לשתף מידע שאינו מאפשר זיהוי אישי עם כלל הציבור ועם שותפים שלנו – כגון בעלי אתרים, מפרסמים, מפתחים או בעלי זכויות. לדוגמה, אנו משתפים מידע באופן ציבורי כדי להראות מגמות לגבי השימוש הכללי בשירותים שלנו.
            <br />
            אם Shop N' Dine מעורבת במיזוג, ברכישה או במכירת נכסים, נמשיך להבטיח את הסודיות של המידע האישי ונשלח הודעה מוקדמת למשתמשים הרלוונטיים לפני שהמידע יימסר או יהיה כפוף למדיניות פרטיות שונה.
            <br /><br /><br />
            הנתונים שאנו אוספים נשמרים על ידינו למשך תקופות זמן שונות. משך הזמן תלוי באופי הנתונים, באופן שבו אנחנו משתמשים בהם

            <h4> מתי המדיניות הזו חלה </h4>
            <br />
            מדיניות הפרטיות הזו חלה על כל השירותים ש-Shop N' Dine מציעה, כולל שירותים שמוצעים באתרי צד שלישי. מדיניות הפרטיות הזו לא חלה על שירותים עם מדיניות פרטיות נפרדת שאינה כוללת את מדיניות הפרטיות הזו.
            <br />
            מדיניות הפרטיות הזו לא חלה על:
            <ul>
                <li>
                    נוהלי הטיפול במידע אצל חברות וארגונים אחרים שמפרסמים את השירותים שלנו
                </li>
                <li>
                    שירותים שמציעים חברות או אנשים אחרים, לרבות מוצרים או אתרים העשויים לכלול את שירותי Shop N' Dine, להופיע בתוצאות החיפוש או שאפשר להגיע אליהם דרך קישור מהשירותים שלנו.
                </li>
            </ul>

            <h4> שינויים במדיניות זו </h4>
            <br />
            אנחנו משנים את מדיניות הפרטיות הזו מדי פעם. אנו לא נצמצם את זכויותיך המפורטות במסגרת מדיניות פרטיות זו ללא הסכמתך המפורשת. אם השינויים מהותיים, נספק הודעה בולטת יותר (כולל, בשירותים מסוימים, הודעת אימייל לגבי שינויים במדיניות הפרטיות).
        </div>
    );
};