import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    loadingOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: '10',
        background: '#9998',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
    },
    loadingSpinner: {
        position: 'absolute',
        top: '50%',
        left: '50%',
    },
}));

const LoadingOverlay = () => {
    let classes = useStyles();

    return (
        <div className={classes.loadingOverlay}>
            <CircularProgress className={classes.loadingSpinner} disableShrink />
            <span style={{ marginTop: '7em', color: 'blue' }}>
                This may take a while...
            </span>
        </div>
    );
};

export default LoadingOverlay;