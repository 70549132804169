import React, { useReducer, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import { Grid, Cell } from 'styled-css-grid';
import { sendContactForm } from '../../../../utils/db';
import Alert from '../../../Alert/Alert';
import { useSnackBar } from '../../../../hooks/UseSnackBar/UseSnackBar';

const useStyles = makeStyles(theme => ({
    mainDiv: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    contactFormGrid: {
        width: '100%',
        '& .MuiTextField-root': {
            boxSizing: 'border-box',
            width: '100%',
        },
    },
}));

function reducer(state, action) {
    if (action.type === 'reset')
        return {};

    return {
        ...state,
        [action.type]: action.value,
    };
}

const ContactUs = () => {
    const [contactForm, dispatchContactForm] = useReducer(reducer, {});
    const [sending, setSending] = useState(false);

    const [snackBarValue, setSnackBarValue] = useSnackBar();

    let classes = useStyles();

    const submitContactForm = (e) => {
        e.preventDefault();

        if (sending)
            return;

        setSending(true);
        sendContactForm(contactForm).then(
            (res) => {
                if (res.status !== 200) {
                    setSending(false);
                    setSnackBarValue({ severity: 'error', msg: 'שגיאה, נסה שנית בעוד כמה שניות' });
                    return;
                }

                setSending(false);
                setSnackBarValue({ severity: 'success', msg: 'הטופס נשלח בהצלחה' });
                dispatchContactForm({ type: 'reset' });
            },
            (err) => {
                setSending(false);
                setSnackBarValue({ severity: 'error', msg: 'שגיאה, נסה שנית בעוד כמה שניות' });
            });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarValue(false);
    };

    return (
        <div className={classes.mainDiv} style={{ textAlign: 'right', direction: 'rtl' }}>
            ניתן לשלוח לנו מייל בכתובת &nbsp;
            <a href="mailto:support@shopndine.ofirl.com" style={{ height: '100%', textDecoration: 'underline' }}>
                support@shopndine.ofirl.com
            </a>
            <br /> <br />
            או למלא את הטופס הבא:
            <br /> <br />
            <Grid onSubmit={submitContactForm} as="form" columns="1fr" rows="repeat(auto-fill, auto)" className={classes.contactFormGrid}>
                <Cell>
                    <TextField
                        placeholder="נושא"
                        required
                        disabled={sending}
                        value={contactForm.title ? contactForm.title : ''}
                        onChange={(e) => dispatchContactForm({ type: 'title', value: e.target.value })}
                        variant="outlined"
                    />
                </Cell>
                <Cell>
                    <TextField
                        placeholder="מייל"
                        disabled={sending}
                        value={contactForm.email ? contactForm.email : ''}
                        onChange={(e) => dispatchContactForm({ type: 'email', value: e.target.value })}
                        variant="outlined"
                    />
                </Cell>
                <Cell>
                    <TextField
                        placeholder="תוכן"
                        required
                        disabled={sending}
                        multiline
                        rows="8"
                        // rowsMax="4"
                        value={contactForm.content ? contactForm.content : ''}
                        onChange={(e) => dispatchContactForm({ type: 'content', value: e.target.value })}
                        variant="outlined"
                    />
                </Cell>
                <Cell style={{ justifyItems: 'left', display: 'grid' }}>
                    <Button type="submit" variant="contained" color="primary">
                        {
                            sending ? (<CircularProgress size={24} style={{ color: 'white' }} disableShrink />) : 'שלח'
                        }
                    </Button>
                </Cell>
            </Grid>
            {/* <Snackbar open={snackBarValue !== false} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={snackBarValue.severity}>
                    {snackBarValue.msg}
                </Alert>
            </Snackbar> */}
        </div>
    );
};

export default ContactUs;