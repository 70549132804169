import { useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setSnackBar } from '../../redux/slices/SnackBarSlice';

export const useSnackBar = () => {
    const dispatch = useDispatch();
    const snackBarValue = useSelector((state) => state.snackBar);
    const setSnackBarValue = useCallback((value) => dispatch(setSnackBar(value)), [dispatch]);

    return [snackBarValue, setSnackBarValue];
};