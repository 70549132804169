import React, { useRef, useMemo, useState } from 'react';

// import { Link } from 'react-router-dom';
// import DelayLink from 'react-delay-link';

import { makeStyles } from '@material-ui/core/styles';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import InputBase from '@material-ui/core/InputBase';

import { isMobile } from "react-device-detect";

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

// import DirectionsIcon from '@material-ui/icons/Directions';

const useStyles = makeStyles(theme => ({
    topBarContainer: {
        zIndex: '2',
        position: 'absolute',
        top: ' 0.5em',
        right: '0.5em',
        left: '1em',
        display: 'grid',
        justifyItems: 'end',
    },
    selectedBranch: {
        background: '#3f51b5 !important',
        color: 'white !important',
        "&:hover": {
            background: '#24327e !important',
        },
    },
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: ({ isMobile }) => isMobile ? '100%' : '30%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    backDrop: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '3',
        background: '#3338',
    },
}));

const TopBar = ({ branchType, changeBranchType, onFilterInput, forceSearch, mainMenuOpen, setMainMenuOpen }) => {
    let inputRef = useRef();
    const [inputValue, setInputValue] = useState('');

    let classes = useStyles({ isMobile });

    const clearFilter = () => {
        inputRef.current.value = "";
        forceSearch("");
    };

    const blurInput = () => {
        inputRef.current.blur();
    };

    const onSearchInput = (e) => {
        onFilterInput(e.target.value);
        setInputValue(e.target.value);
    };

    const searchInputDirection = useMemo(() => {
        let val = inputValue;
        return val && val.length > 0 && val[0].match(/[a-zA-Z0-9]/) ? 'ltr' : 'rtl';
    }, [inputValue]);

    return (
        <div className={classes.topBarContainer}>
            <Paper component="form" onSubmit={(e) => { blurInput(); e.preventDefault(); }} className={classes.root}>
                <IconButton onClick={(e) => blurInput()} className={classes.iconButton} aria-label="search">
                    <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton onClick={clearFilter} className={classes.iconButton} aria-label="search">
                    <CloseIcon />
                </IconButton>
                <InputBase
                    className={classes.input}
                    placeholder="חיפוש"
                    inputProps={{ style: { direction: searchInputDirection } }}
                    inputRef={inputRef}
                    onInput={onSearchInput}
                />
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton onClick={() => setMainMenuOpen(true)}>
                    <MenuIcon />
                </IconButton>
                {/* <ToggleButtonGroup
                    size="small"
                    value={branchType}
                    exclusive
                    onChange={changeBranchType}
                    aria-label="text alignment"
                >
                    <ToggleButton value="flavor" classes={{ selected: classes.selectedBranch }}>
                        <DelayLink delay={100} to="/flavor" clickAction={changeBranchType}>
                            טעמים
                        </DelayLink>
                    </ToggleButton>
                    <ToggleButton value="yellow" classes={{ selected: classes.selectedBranch }}>
                        <DelayLink delay={100} to="/yellow" clickAction={changeBranchType}>
                            קבע
                        </DelayLink>
                    </ToggleButton>
                </ToggleButtonGroup> */}
            </Paper>
        </div>
    );
};

export default TopBar;