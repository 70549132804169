import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { GoogleMap, useLoadScript, Marker, Circle, MarkerClusterer } from '@react-google-maps/api';
// import { getBranches } from '../../utils/db';
// import { Grid, Cell } from 'styled-css-grid';
import { darkModeOption, defaultMapControlsOption } from './consts';
import { trackSearch, trackEvent } from '../../utils/matomo';
import { isMobile } from 'react-device-detect';

const navigationIconPath = "M12 2L4.5 20.29l.71.71L12 18l6.79 3 .71-.71z";

const baseBranchIconPath = "https://shopndine.ofirl.com/icons/";
const googleBaseIcons = "http://maps.google.com/mapfiles/ms/icons/";
const branchIcons = {
    flavor: 'blue-dot.png',
    yellow: 'yellow-dot.png',
    ticketStations: 'orange-dot.png',
};
// const branchIcons = {
//     flavor: 'restaurant.png',
//     yellow: 'store.png',
// };
const getBranchIcon = (branchType) => baseBranchIconPath + branchIcons[branchType];

const Map = ({ zoom, initialCenter = { lat: 31.629078, lng: 35.000076 }, center, currentLocation, selectedBranch, setSelectedBranch,
    unforceCenter, loaded, loading, branchType, filter, mapClicked, streetViewVisible, setStreetViewVisible, branches, infoWindowState,
    setInfoWindowState, darkMode }) => {
    // console.log('map rendered!');
    const [currentCenter, setCurrentCenter] = useState(center ? initialCenter : center);
    const [currentZoom, setCurrentZoom] = useState(zoom ? zoom : 8);
    const [markerRefresh, setMarkerRefresh] = useState(false);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY // ,
        // ...otherOptions
    });

    const mapInstanceRef = useRef();

    let onMarkerClick = useCallback((branch, marker, e) => {
        // let branchDisplayName = branchType === 'flavor' ? `${branch.branches[0].name}, ${branch.branches[0].address}` : `${branch.branches[0].company}, ${branch.branches[0].name}`;
        // trackEvent('Marker', 'Show info window', branchDisplayName);
        setSelectedBranch(branch);
        setInfoWindowState(isMobile ? 1 : 2);
    }, [/*branchType,*/ setSelectedBranch, setInfoWindowState]);

    let filteredBranches = useMemo(() => {
        let searchFields = ['name', 'address', 'category', 'desc', 'hours', 'kosher', 'phone', 'city', 'type', 'company', 'types', 'place_id'];
        let filterValue = filter.toLowerCase();
        let tempBranches = filter !== '' ? (() => {
            let resultBranches = branches.filter((b) =>
                searchFields.some((f) => b[f] != null && b[f].toString().toLowerCase().includes(filterValue))
            );
            trackSearch(filterValue, branchType, resultBranches.length);
            return resultBranches;
        })() : branches;
        loaded();
        return tempBranches;
    }, [branches, filter, loaded, branchType]);

    let branchIcon = useMemo(() =>
        getBranchIcon(branchType),
        [branchType]);

    let image = useMemo(() => ({
        url: branchIcon,
        size: { width: 40, height: 40 },
        scaledSize: { width: 40, height: 40 },
    }), [branchIcon]);

    let selectedBranchImage = useMemo(() => ({
        url: branchIcon,
        // scale: 2,
        size: { width: 45, height: 45 },
        scaledSize: { width: 45, height: 45 },
    }), [branchIcon]);

    let collapsedBranches = useMemo(() => {
        let result = [];
        filteredBranches.forEach((b) => {
            let markerObj = result.find((r) => r.lat === b.lat && r.lng === b.lng);
            if (markerObj == null) {
                markerObj = {
                    lat: b.lat,
                    lng: b.lng,
                    branches: []
                };
                result.push(markerObj)
            }
            markerObj.branches.push(b);
        });

        return result;
    }, [filteredBranches]);

    let markerArray = useMemo(() => collapsedBranches.map((b, idx) => !markerRefresh || (selectedBranch && b.place_id !== selectedBranch.place_id) ?
        (clusterer) => <Marker onClick={(e) => onMarkerClick(b, e.target, e)} clusterer={clusterer} key={idx} //branch={b}
            position={{ lat: parseFloat(b.lat), lng: parseFloat(b.lng) }} icon={selectedBranch && selectedBranch.branches.some((sb) => b.branches.some((cb) => sb.place_id === cb.place_id)) ? selectedBranchImage : image} />
        : null
    ), [collapsedBranches, onMarkerClick, image, selectedBranchImage, selectedBranch, markerRefresh]);

    useEffect(() => {
        setMarkerRefresh(true);
        setTimeout(() => {
            setMarkerRefresh(false);
        }, 100);
    }, [selectedBranch]);

    if (center == null) {
        center = initialCenter;
    }
    else {
        if (currentCenter && center && (currentCenter.lat !== center.lat || currentCenter.lng !== center.lng)) {
            setCurrentCenter(center);
            mapInstanceRef.current.setZoom(20);
        }
    }

    if (zoom != null) {
        if (zoom !== currentZoom)
            setCurrentZoom(zoom);
    }

    if (center != null || zoom != null)
        setTimeout(unforceCenter(), 1);

    let onMapClicked = (props) => {
        mapClicked && mapClicked();
        if (infoWindowState !== 0) {
            setInfoWindowState(isMobile ? 0 : 1);
            // setSelectedBranch(null);
        }
    };

    const currentLocationCircleOptions = {
        strokeColor: '#4285F4',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#4285F4',
        fillOpacity: 0.35,
        clickable: false,
    };

    if (loadError) {
        return <div>Map cannot be loaded right now, sorry.</div>
    }

    if (!isLoaded)
        return (
            <div>
                loading...
            </div>
        );

    const streetViewVisibleChanged = () => {
        let isStreetViewVisibile = mapInstanceRef.current.getStreetView().getVisible();
        trackEvent('Street View', `${isStreetViewVisibile ? 'Enter' : 'Exit'} street view`);
        setStreetViewVisible(isStreetViewVisibile);
    };

    const onLoad = (mapInstance) => {
        mapInstanceRef.current = mapInstance;
        let streetView = mapInstance.getStreetView();
        window.google.maps.event.addListener(streetView, 'visible_changed', streetViewVisibleChanged);
    }

    const onCenterChanged = () => {
        if (mapInstanceRef.current == null)
            return;

        let newCenter = {
            lat: mapInstanceRef.current.center.lat(),
            lng: mapInstanceRef.current.center.lng(),
        };
        if (currentCenter == null || (currentCenter.lat !== newCenter.lat || currentCenter.lng !== newCenter.lng))
            setCurrentCenter(newCenter);
    }

    const onZoomChanged = () => {
        if (mapInstanceRef.current == null)
            return;

        let newZoom = mapInstanceRef.current.getZoom();
        if (currentZoom !== newZoom)
            setCurrentZoom(newZoom);
    };

    const currentLocationIcon = {
        path: navigationIconPath,
        fillOpacity: 1,
        fillColor: "#4285F4",
        rotation: currentLocation && currentLocation.heading ? currentLocation.heading : null,
        // scale: 2,
        strokeColor: '#4285F4',
        strokeOpacity: 0.8,
        origin: { x: 0, y: 0 },
        anchor: { x: 12, y: 13 },
    };

    let mapOptions = {
        ...defaultMapControlsOption
    };

    if (darkMode)
        mapOptions = {
            ...mapOptions,
            ...darkModeOption
        };

    return <GoogleMap
        options={mapOptions}
        onLoad={onLoad}
        zoom={currentZoom}
        mapContainerStyle={{
            position: 'relative',
            height: "100%",
            width: "100%"
        }}
        center={currentCenter ? currentCenter : initialCenter}
        onCenterChanged={onCenterChanged}
        onZoomChanged={onZoomChanged}
        onClick={onMapClicked}
    >
        {
            !loading && branches ? (
                <MarkerClusterer
                    gridSize={30}
                    options={{
                        imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
                    }}
                >
                    {
                        (clusterer) => markerArray.map((m) => m && m(clusterer))
                    }
                </MarkerClusterer>
            ) : null
        }
        {
            currentLocation ? (
                <>
                    <Marker position={{ lat: currentLocation.latitude, lng: currentLocation.longitude }} icon={currentLocationIcon} zIndex={1} options={{ clickable: false }} />
                    <Circle center={{ lat: currentLocation.latitude, lng: currentLocation.longitude }} radius={currentLocation.accuracy} options={currentLocationCircleOptions} />
                </>
            ) : null
        }
    </GoogleMap>
};

export default Map;