import { createSlice } from '@reduxjs/toolkit';

const snackBarReducer = createSlice({
  name: 'snackBar',
  initialState: false,
  reducers: {
    setSnackBar: (state, action) => action.payload,
  },
});

// Extract the action creators object and the reducer
export const { actions, reducer } = snackBarReducer;

export const { setSnackBar } = actions;

// Export the reducer, either as a default or named export
export default reducer;