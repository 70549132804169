import React from 'react';

import {
  BrowserRouter as Router,
  // Router,
  Route,
  Switch,
  // Link,
  // useParams
} from "react-router-dom";

import AuthCheck from './components/AuthCheck/AuthCheck';

import './App.css';

function App() {
  return (
    <Router>
      <Route path="/" component={AuthCheck} />
    </Router>
  );
}

export default App;