import React from 'react';
import { isMobile } from 'react-device-detect';

export const About = () => {
    return (
        <div style={{ direction: 'rtl' }}>
            <div style={{ float: isMobile ? 'left' : 'left', margin: '1em', width: isMobile ? '8em' : '8em', height: '8em' }}>
                <img alt="logo" src="/shopndine-logo.png" style={{ height: '100%', width: '100%' }} />
            </div>

            אתר זה הוקם במטרה להנגיש לאנשי הקבע את כלל המידע הנוגע למיקומים המכבדים כרטיס ״חבר טעמים״ ו״חבר של קבע״.
            <br />
            <br />
            ניתן לחפש ולסנן מקומות באיזורכם לפי פרמטרים שונים כגון: שם המקום, כתובת, סוג המקום וכדומה.
            <br />
            <br />
            מועדון "חבר" הינו מועדון המתכלל את כל תחומי החיים ולכן ישנם מגוון של מקומות אשר מקבלים את כרטיסי "חבר טעמים" ו"חבר של קבע".
            <br />
            <br />
            אנו זקוקים לתצוגת מפה על מנת למצות את מלוא המידע.לכן, מתוך ההבנה הזו ומתוך הרצון האישי שלי בהכוונה זו, הקמתי אתר זה לשירות חברי המועדון.
            <br />
            <br />
            האתר הוקם ללא מטרות רווח ומונגש לשירות ציבור חברי מועדון צרכנות ״חבר״.
        </div>
    );
};