import React, { useMemo, useState, useCallback } from 'react';

import { makeStyles } from '@material-ui/core/styles';

// import Fab from '@material-ui/core/Fab';
import { Input, Button } from '@material-ui/core';

// import DirectionsIcon from '@material-ui/icons/Directions';
import CallIcon from '@material-ui/icons/Call';
// import ShareIcon from '@material-ui/icons/Share';
// import MapIcon from '@material-ui/icons/Map';

import PlaceIcon from '@material-ui/icons/Place';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PublicIcon from '@material-ui/icons/Public';
import EditIcon from '@material-ui/icons/Edit';

import { Grid, Cell } from 'styled-css-grid';
import BasicActions from '../BasicActions/BasicActions';
import { stopPropagatingMouseTouch } from '../../../../utils/events';
import { sendSuggestionForm } from '../../../../utils/db';

const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'end',
        paddingLeft: '2em',
        '& > *': {
            borderTop: '1px solid #99999926',
        },
    },
    rowValue: {
        '& a:-webkit-any-link': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
    },
}));

const OverviewRow = ({ icon, value }) => {
    let classes = useStyles();

    return (
        <Grid rows="1fr" columns="1fr 3em">
            <Cell className={classes.rowValue} style={{ alignItems: 'center', display: 'grid' }}>
                {value}
            </Cell>
            <Cell style={{ color: '#3f51b5', display: 'grid', alignItems: 'center', justifyItems: 'end' }}>
                {icon}
            </Cell>
        </Grid>
    );
};

const HoursRow = ({ title, value }) => {
    return (
        <Grid rows="1fr" columns="auto auto">
            <Cell style={{ textAlign: 'start' }}>
                {value ? value : 'סגור'}
            </Cell>
            <Cell style={{ textAlign: 'end' }}>
                {title}
            </Cell>
        </Grid>
    );
};

const HoursGrid = ({ hours15, hours6, hours7 }) => {
    return (
        <Grid columns="1fr" rows="repeat(3, minmax(1.5em, auto))">
            <Cell>
                <HoursRow title={'ראשון - חמישי'} value={hours15} />
            </Cell>
            <Cell>
                <HoursRow title={'שישי'} value={hours6} />
            </Cell>
            <Cell>
                <HoursRow title={'שבת'} value={hours7} />
            </Cell>
        </Grid>
    );
};

const Overview = ({ branch, branchType, shareBranch }) => {
    const [editMode, setEditMode] = useState(false);
    const [suggestion, setSuggestion] = useState({});

    const classes = useStyles();

    const inputChanged = useCallback((changeObj) => {
        setSuggestion({ ...suggestion, ...changeObj });
    }, [suggestion]);

    const sendSuggestion = useCallback(() => {
        sendSuggestionForm({ ...branch, id: branch._id, ...suggestion });
        setEditMode(false);
    }, [suggestion, branch]);

    let branchDetails = useMemo(() => {
        let websiteUrl = branch.website !== "" ? branch.website : null;
        if (websiteUrl && !websiteUrl.startsWith('http'))
            websiteUrl = 'http://' + websiteUrl;

        let branchDetails = []

        if (branchType !== "ticketStations")
            branchDetails.push(
                {
                    icon: <PlaceIcon />,
                    value: editMode ?
                        <Input dir="rtl" defaultValue={branch.address} onInput={(e) => inputChanged({ address: e.target.value })} />
                        : branch.address,
                }
            );

        if (branchType !== "ticketStations")
            branchDetails.push(
                {
                    icon: <FingerprintIcon />,
                    value: branch.place_id,
                }
            );

        if (branchType !== "ticketStations" && (branch.hours15 || branch.hours6 || branch.hours7))
            branchDetails.push(
                {
                    icon: <ScheduleIcon />,
                    value: <HoursGrid hours15={branch.hours15} hours6={branch.hours6} hours7={branch.hours7} />
                }
            );

        if (branch.phone || editMode)
            branchDetails.push({
                icon: <CallIcon />,
                value: editMode ? <Input defaultValue={branch.phone} onInput={(e) => inputChanged({ phone: e.target.value })} />
                    : <a key="phone" href={`tel:${branch.phone}`}>
                        {branch.phone}
                    </a>
            });

        if (websiteUrl || editMode)
            branchDetails.push({
                icon: <PublicIcon />,
                value: editMode ? <Input defaultValue={decodeURI(websiteUrl)} onInput={(e) => inputChanged({ websiteUrl: e.target.value })} />
                    : <a key="website" href={websiteUrl} target="_blank" rel="noopener noreferrer">
                        {websiteUrl.substring(websiteUrl.indexOf('://') + 3, websiteUrl.indexOf('/', websiteUrl.indexOf('://') + 3))}
                    </a>
            });

        if (branchType !== "ticketStations")
            branchDetails.push({
                icon: <EditIcon />,
                value: editMode ?
                    <Grid rows="1fr" columns="auto 1fr auto">
                        <Button variant="outlined" color="secondary" onClick={() => setEditMode(false)}> ביטול </Button>
                        <Cell> </Cell>
                        <Button variant="contained" color="primary" onClick={sendSuggestion}> אישור </Button>
                    </Grid>
                    : <span onClick={() => setEditMode(true)}>
                        הצעה לשינוי
                </span>
            });

        return branchDetails;
    }, [branch.address, branch.hours15, branch.hours6, branch.hours7, branch.phone, branch.place_id, branch.website, branch.hours, branchType, editMode, inputChanged, sendSuggestion]);

    return (
        <Grid className={classes.container} rows="repeat(10, minmax(1.5em, auto))" columns="1fr">
            <Cell>
                Overview
            </Cell>
            <Cell>
                <BasicActions branch={branch} branchType={branchType} shareBranch={shareBranch} />
            </Cell>
            {branchDetails.map((d, idx) =>
                <Cell key={idx}>
                    <OverviewRow {...d} />
                </Cell>
            )}
        </Grid>
    );
};

export default Overview;