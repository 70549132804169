import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useParams } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';

import Map from '../Map/Map';

import { getLastUpdate, getRefreshStatus, getBranches, getChangedAt } from '../../utils/db';

import { Grid, Cell } from 'styled-css-grid';
import TopBar from '../TopBar/TopBar';
import { trackPageChange, trackEvent } from '../../utils/matomo';
import Menu from '../Menu/Menu';
import LoadingOverlay from '../LoadingOverlay/LoadingOverlay';
import BottomBar from '../BottomBar/BottomBar';

import MyLocationIcon from '@material-ui/icons/MyLocation';

import Fab from '@material-ui/core/Fab';
import Snackbar from '@material-ui/core/Snackbar';

import { useDrag } from 'react-use-gesture';
import MainMenu from '../MainMenu/MainMenu';
import usePosition from '../../hooks/UsePosition/UsePosition';
import Alert from '../Alert/Alert';
import { isMobile } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import { setSnackBar } from '../../redux/slices/SnackBarSlice';
import { useSnackBar } from '../../hooks/UseSnackBar/UseSnackBar';

// const humanizeDuration = require('humanize-duration');

const useStyles = makeStyles(theme => ({
    backDrop: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: '3',
        background: '#3338',
    },
    mapGrid: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        // transition: 'all 0.5s ease',
        overflow: 'hidden',
    },
    bottomBarContainer: {
        transition: ({ down }) => down ? null : 'all 0.5s ease',
        zIndex: '5',
        position: 'absolute',
        bottom: '0',
        right: '0',
        width: isMobile ? '100%' : '30%',
        height: ({ infoWindowState, selectedBranch, deltaY }) => {
            if (infoWindowState === 2)
                return `calc(100% - 4em + ${-deltaY}px)`;
            if (infoWindowState > 2)
                return `100%`;
            if (infoWindowState === 1)
                return selectedBranch ? `calc(12em + ${-deltaY}px)` : '0px';
            if (selectedBranch)
                return infoWindowState === 0 ? `calc(3em + ${-deltaY}px)` : '0px';

            return '0px';

            // return infoWindowState !== 0 && selectedBranch ? `calc(18em + ${-y}px)` : '0px';
        },
    },
    myLocationFab: {
        float: 'left',
        position: 'absolute',
        bottom: '0',
        paddingLeft: '1em',
        paddingBottom: '6em',
    },
    myLocationFabRoot: {
        backgroundColor: ({ darkMode }) => darkMode ? 'transparent' : '#3f51b5',
        '&:hover': {
            color: '#979797',
            backgroundColor: 'transparent',
        },
    },
}));

const MapPage = () => {
    // console.log('map page rendered!');
    const [streetViewVisible, setStreetViewVisible] = useState(false);

    const [refreshing, setRefreshing] = useState(false);
    const [loading, setLoading] = useState(true);

    const [currentPosition, currentPositionTimestamp, currentPositionOutdated] = usePosition();
    const [forceCenter, setForceCenter] = useState(null);

    const [darkMode, setDarkMode] = useState(false);

    let { branchType = 'flavor', section, place_id } = useParams();
    const [openAtLocation, setOpenAtLocation] = useState(place_id);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [branches, setBranches] = useState([]);
    let getBranchesCall = useRef(false);

    // infoWindowState: 0 = hidden, 1 = basic, 2 = details + photos, 3 = details
    const [infoWindowState, setInfoWindowState] = useState(0);

    const [menuOpen, setMenuOpen] = React.useState(false);
    const [mainMenuOpen, setMainMenuOpen] = useState(false);

    const [lastUpdate, setLastUpdate] = useState(null);
    const [forceBranchRefresh, setForceBranchRefresh] = useState(false);
    const [filter, setFilter] = useState('');
    const loaded = useCallback(() => { setLoading(false); setRefreshing(false); }, []);

    let refreshWaiterTimeout = useRef(false);
    let filterChangeTimeout = useRef(false);

    const [deltaY, setDeltaY] = useState(0);
    const [down, setDown] = useState(0);

    const [snackBarValue, setSnackBarValue] = useSnackBar();

    const bind = useDrag(({ down, movement: [mx, my], last, tap, touches, buttons }) => {
        // if (mx > 200) cancel()
        if (!isMobile)
            return;

        setDeltaY(my);
        setDown(down);

        if (tap && infoWindowState < 2 && mx < 10 && deltaY < 10)
            setInfoWindowState(infoWindowState + 1);

        if (!last)
            return;

        if (-deltaY > 100) {
            if (infoWindowState < 3)
                setInfoWindowState(infoWindowState + 1);
        }
        if (-deltaY < -100) {
            if (infoWindowState > 0)
                setInfoWindowState(infoWindowState - 1);
        }
        if (-deltaY < 0 && infoWindowState === 0)
            setSelectedBranch(null);

        setDeltaY(0);
    }, {
        axis: 'y'
    });

    let classes = useStyles({ infoWindowState, selectedBranch, deltaY, down, darkMode });

    useEffect(() => {
        (async () => {
            let lastRemoteChange = await getChangedAt(branchType);
            if (!lastRemoteChange) {
                // TODO: error getting last changed
                console.error('Error getting last changed');
                return;
            }
            lastRemoteChange = lastRemoteChange.data.changedAt;
            let lastLocalChange = localStorage.getItem(`${branchType}.changedAt`);
            if (lastLocalChange == null || lastLocalChange < lastRemoteChange) {
                console.log('updating local storage from server');
                localStorage.setItem(`${branchType}.changedAt`, lastRemoteChange);
                setForceBranchRefresh(true);
            }
        })();
    }, [branchType]);

    useEffect(() => {
        if (section)
            setMainMenuOpen(true);
    }, [section]);

    let refreshHandler = async () => {
        trackEvent('Menu', 'Button Click', 'Refresh');
        localStorage.removeItem(`branches.${branchType}`);
        setRefreshing(true);
        setLoading(true);
    };

    useEffect(() => {
        trackPageChange(branchType);
    }, [branchType]);

    let centerCurrentLocation = () => {
        if (currentPosition) {
            trackEvent('Menu', 'Button Click', 'Center on current location');
            setForceCenter({ lat: currentPosition.latitude, lng: currentPosition.longitude });
        }
    };

    let changeBranchType = (onlyLoad = false) => {
        if (!onlyLoad)
            setSelectedBranch(null);
        setLoading(true);
    };

    const applySearchFilter = (val) => {
        setFilter(val);
    };

    const forceSearch = (newFilter) => {
        if (filterChangeTimeout.current)
            clearTimeout(filterChangeTimeout.current);

        filterChangeTimeout.current = setTimeout((val) => {
            // setLoading(true);
            applySearchFilter(val);
            setSelectedBranch(null);
        }, 500, newFilter);
    };

    const onFilterInput = (newFilter) => {
        if (filterChangeTimeout.current)
            clearTimeout(filterChangeTimeout.current);

        filterChangeTimeout.current = setTimeout((val) => {
            setLoading(true);
            applySearchFilter(val);
        }, 500, newFilter);
    };

    if (lastUpdate == null)
        (async () => {
            setLastUpdate((await getLastUpdate()).data.result);
        })();

    const handleSnackBarClosed = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackBarValue(false);
    };

    useEffect(() => {
        let getData = async () => {
            getBranchesCall.current = true;
            let data = JSON.parse(localStorage.getItem(`branches.${branchType}`));
            if (data == null || data === "" || data.length === 0 || forceBranchRefresh)
                data = await getBranches(branchType);
            if (data === false) {
                // TODO: show error - snackbar + alert
                getBranchesCall.current = false;
                return;
            }
            setBranches(data);
            localStorage.setItem(`branches.${branchType}`, JSON.stringify(data));
            if (forceBranchRefresh)
                setForceBranchRefresh(false);
            loaded();
            getBranchesCall.current = false;
        };
        !getBranchesCall.current && getData();
    }, [refreshing, branchType, loaded, setBranches, forceBranchRefresh]);

    useEffect(() => {
        if (branches && branches.length > 0 && openAtLocation) {
            let branch = branches.find((b) => b.place_id === openAtLocation);
            setTimeout(() => {
                setSelectedBranch(branch);
                setForceCenter(branch);
                setInfoWindowState('basic');
                setOpenAtLocation(null);
            }, 2000);
        }
    }, [branches, openAtLocation]);

    useEffect(() => {
        if (currentPositionOutdated)
            setSnackBarValue({ severity: 'error', msg: 'לא התקבלו נתוני מיקום, מיקומך יכול להיות לא מעודכן' });
        else
            setSnackBarValue(false);
    }, [currentPositionOutdated, setSnackBarValue]);

    // dark mode since 8 PM and before 4 AM
    let currentHour = new Date(Date.now()).getHours();
    if (currentHour > 19 || currentHour < 5) {
        if (!darkMode)
            setDarkMode(true);
    }
    else {
        if (darkMode)
            setDarkMode(false);
    }

    let mapProps = {
        branchType,
        // refreshing,
        darkMode,
        currentLocation: currentPosition,
        center: forceCenter ? forceCenter : undefined,
        unforceCenter: () => setForceCenter(null),
        loading,
        loaded,
        zoom: forceCenter ? 17 : undefined,
        filter,
        mapClicked: () => setMenuOpen(false),
        streetViewVisible,
        setStreetViewVisible,
        selectedBranch,
        setSelectedBranch,
        branches,
        // setBranches,
        infoWindowState,
        setInfoWindowState,
    };

    let menuProps = {
        refreshing,
        changeBranchType,
        menuOpen,
        setMenuOpen,
        branchType,
    };

    let topBarProps = {
        branchType,
        onFilterInput,
        changeBranchType,
        infoWindowState,
        forceSearch,
        mainMenuOpen,
        setMainMenuOpen,
    };

    let mainMenuProps = {
        open: mainMenuOpen,
        close: () => setMainMenuOpen(false),
        refreshHandler,
        centerCurrentLocation,
    };

    return (
        <div className="App">
            {
                loading ? (
                    <LoadingOverlay />
                ) : null
            }
            <Grid gap="0" className={classes.mapGrid} columns="1fr" rows={`auto 1fr auto ${isMobile && selectedBranch ? (infoWindowState !== 0 ? `calc(12em + ${-deltaY > 150 ? 150 : -deltaY}px)` : `calc(3em + ${-deltaY > 150 ? 150 : -deltaY}px)`) : '0px'}`}
                areas={["top", "map", "menu", "bottombar"]}>
                <Cell top={1} left={1} height={4} width={1}>
                    {/* fabs */}
                    <div style={{ height: '100%', position: 'relative' }}>
                        {/* actual map */}
                        <Map {...mapProps} />
                    </div>
                </Cell>
                <Cell area="top">
                    <TopBar {...topBarProps} />
                </Cell>
                <Cell area="menu" style={{ marginRight: !isMobile && selectedBranch ? '30%' : null }}>
                    {
                        !streetViewVisible ? (
                            <>
                                <Menu {...menuProps} />
                                <div className={classes.myLocationFab}>
                                    <Fab color="primary" size="small" classes={{ root: classes.myLocationFabRoot }} onClick={centerCurrentLocation}>
                                        <MyLocationIcon />
                                    </Fab>
                                </div>
                            </>
                        ) : null
                    }
                </Cell>
                <Cell top={4} left={1} height={1} width={1} style={{ zIndex: '3' }}>
                    {/* <BottomBar branch={selectedBranch} branchType={branchType} currentLocation={currentLocation} infoWindowState={infoWindowState} /> */}
                </Cell>
            </Grid>
            <div {...bind()} className={classes.bottomBarContainer}>
                <BottomBar branch={selectedBranch} branchType={branchType} currentLocation={currentPosition} infoWindowState={infoWindowState} />
            </div>
            <MainMenu {...mainMenuProps} />
            {/* snack bar */}
            <Snackbar open={snackBarValue !== false} autoHideDuration={6000} onClose={handleSnackBarClosed}>
                <Alert onClose={handleSnackBarClosed} severity={snackBarValue.severity}>
                    {snackBarValue.msg}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default MapPage;