const getPaq = () => {
    return window._paq;
};

export const trackPageChange = (title) => {
    let paq = getPaq();
    if (paq == null)
        return;

    if (title == null || title === "")
        return;

    paq.push(['setDocumentTitle', title]);
    paq.push(['setGenerationTimeMs', 0]);
    paq.push(['trackPageView']);
}

export const trackSearch = (searchValue, searchCategory, resultsNum) => {
    let paq = getPaq();
    if (paq == null)
        return;

    if (searchValue == null)
        return;

    paq.push(['trackSiteSearch', searchValue, searchCategory != null ? searchCategory : false, resultsNum != null ? resultsNum : false]);
};

export const trackEvent = (category, action, name, value) => {
    let paq = getPaq();
    if (paq == null)
        return;

    if (category == null || category === "" || action == null || action === "")
        return;

    paq.push(['trackEvent', category, action, name, value]);
};